/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '@bluheadless/ui/src/atoms/icon/icon'

const LightBlurredArrowRightSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 2"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			d="M10.5 21C16.3 21 21 16.3 21 10.5S16.3 0 10.5 0 0 4.7 0 10.5 4.7 21 10.5 21zm2.07-10.33c.15-.17.15-.42 0-.59l-3.5-3.81a.441.441 0 00-.62-.03c-.18.16-.19.44-.03.62l3.23 3.51-3.23 3.51c-.16.18-.15.45.03.62.18.16.45.15.62-.03l3.5-3.81z"
			fill="currentColor"
			fillRule="evenodd"
			opacity={0.3}
		/>
	</svg>
))
LightBlurredArrowRightSvg.displayName = 'LightBlurredArrowRightSvg'

const LightBlurredArrowRight = forwardRef((props, ref) => (
	<Icon component={LightBlurredArrowRightSvg} ref={ref} {...props} />
))
LightBlurredArrowRight.displayName = 'LightBlurredArrowRight'

LightBlurredArrowRight.defaultProps = {
	...Icon.defaultProps,
}
LightBlurredArrowRight.propTypes = {
	...Icon.propTypes,
}

export default LightBlurredArrowRight
export { LightBlurredArrowRightSvg }
