import { cx } from '@emotion/css'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/system'
import { array, object, oneOf, oneOfType, string } from 'prop-types'
import { useState } from 'react'
import Slider, { Slide } from '../../particles/slider'
import { MainSliderContainer, Root, SliderThumbs } from './main-slider.styled'
import { LightBlurredArrowRightSvg } from '@/components/ui/atoms/icons/light-blurred-arrow-right'
import { LightBlurredArrowLeftSvg } from '@/components/ui/atoms/icons/light-blurred-arrow-left'

const MainSlider = ({
	aboveTheFold,
	direction,
	heightVerticalLg,
	heightVerticalMd,
	verticalObjectPosition,
	pagination,
	thumbs,
	thumbsProps,
	className,
	children,
	...props
}) => {
	const [thumbsSwiper, setThumbsSwiper] = useState(null)

	const theme = useTheme()
	const smUp = useMediaQuery(theme.breakpoints.up('sm'))
	const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
	const mdUp = useMediaQuery(theme.breakpoints.up('md'))

	const paginationElSelector = '.swiper-pagination'

	return (
		<Root className={cx('MainSlider-root', className)} aboveTheFold={aboveTheFold}>
			<MainSliderContainer
				aboveTheFold={aboveTheFold}
				direction={direction}
				heightVertical={lgUp || !mdUp ? heightVerticalLg : heightVerticalMd}
				verticalObjectPosition={verticalObjectPosition}
				thumbsCount={thumbs?.length || 0}
			>
				<Slider
					direction={direction}
					thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
					{...props}
					pagination={
						thumbs?.length > 0
							? false
							: pagination && smUp
							? {
									clickable: true,
									el: paginationElSelector,
							  }
							: pagination
							? pagination
							: false
					}
					iconPrevious={LightBlurredArrowLeftSvg}
					iconNext={LightBlurredArrowRightSvg}
				>
					{children}
				</Slider>
			</MainSliderContainer>

			{thumbs?.length && (
				<SliderThumbs count={thumbs.length} onSwiper={setThumbsSwiper} {...thumbsProps}>
					{thumbs.map((thumb, index) => (
						<Slide key={index}>{thumb}</Slide>
					))}
				</SliderThumbs>
			)}
		</Root>
	)
}

MainSlider.defaultProps = {
	...Slider.defaultProps,
	heightVerticalLg: '900px',
	heightVerticalMd: '620px',
	verticalObjectPosition: 'center',
	navigation: true,
	pagination: true,
	thumbsProps: {
		slidesPerView: 3,
		spaceBetween: 31,
	},
}

MainSlider.propTypes = {
	...Slider.propTypes,
	/**
	 * Slider height when direction is vertical
	 */
	heightVerticalLg: string,
	/**
	 * Slider height when direction is vertical
	 */
	heightVerticalMd: string,
	/**
	 * Thumbs
	 */
	thumbs: array,
	/**
	 *
	 */
	thumbsProps: object,
	/**
	 * Prop for the object-fit rule when `direction = vertical`.
	 *
	 * [rif. MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/object-position)
	 */
	verticalObjectPosition: oneOfType([oneOf(['top', 'bottom', 'left', 'right', 'center']), string]),
}

export default MainSlider
