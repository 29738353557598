/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '@bluheadless/ui/src/atoms/icon/icon'

const LightBlurredArrowLeftSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 2"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			d="M10.5 0C4.7 0 0 4.7 0 10.5S4.7 21 10.5 21 21 16.3 21 10.5 16.3 0 10.5 0zM8.43 10.33c-.15.17-.15.42 0 .59l3.5 3.81c.16.18.44.19.62.03s.19-.44.03-.62l-3.23-3.51 3.23-3.51c.16-.18.15-.45-.03-.62a.441.441 0 00-.62.03l-3.5 3.81z"
			fill="currentColor"
			fillRule="evenodd"
			opacity={0.3}
		/>
	</svg>
))
LightBlurredArrowLeftSvg.displayName = 'LightBlurredArrowLeftSvg'

const LightBlurredArrowLeft = forwardRef((props, ref) => (
	<Icon component={LightBlurredArrowLeftSvg} ref={ref} {...props} />
))
LightBlurredArrowLeft.displayName = 'LightBlurredArrowLeft'

LightBlurredArrowLeft.defaultProps = {
	...Icon.defaultProps,
}
LightBlurredArrowLeft.propTypes = {
	...Icon.propTypes,
}

export default LightBlurredArrowLeft
export { LightBlurredArrowLeftSvg }
